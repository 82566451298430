import React from 'react';
import { graphql } from 'gatsby';

import Layout from '@components/Layout';
import Breadcrumbs from '@components/Breadcrumbs';
import Hero from '@components/Hero';
import { Grid, Row, Col } from '@components/Grid';

import styles from './styles.module.scss';
import Card from '@components/Card';
import { Divider } from '@components/Grid';
import SEO from '@components/SEO';
import Button from '@components/Button';

const Treatments = ({
    data: {
        page: {
            frontmatter: {
                path,
                name,
                seoTitle,
                seoDescription,
                buttonText,
                buttonLink,
                description,
                terms,
                image,
                items,
            },
        },
    },
}) => {
    return (
        <Layout>
            <SEO title={seoTitle} description={seoDescription} />

            <Breadcrumbs>
                <Breadcrumbs.Breadcrumb title="Spa" link="/spa" />
                <Breadcrumbs.Breadcrumb title={name} link={path} />
            </Breadcrumbs>

            <Hero image={image}>
                <h1>{name}</h1>
                <p>{description}</p>

                {!!buttonText && !!buttonLink && (
                    <>
                        <br />
                        <Button link={buttonLink}>{buttonText}</Button>
                    </>
                )}
            </Hero>

            <Grid>
                <Row>
                    {items.map(({ name, description, prices, extras = [] }) => (
                        <Col xs={12} key={name}>
                            <div className={styles.treatment}>
                                <div className={styles.treatmentContent}>
                                    <p className={styles.treatmentTitle}>{name}</p>
                                    <p className={styles.treatmentDescription}>{description}</p>

                                    {extras && !!extras.length && (
                                        <div className={styles.treatmentExtras}>
                                            {extras.map(extra => (
                                                <span>{extra?.extra}</span>
                                            ))}
                                        </div>
                                    )}
                                </div>

                                <span className={styles.treatmentDivider} />

                                <div className={styles.treatmentPrices}>
                                    {prices.map(({ name = '', price = '' }) => (
                                        <div
                                            key={`${name}-${price}`}
                                            className={styles.treatmentPricesPrice}
                                        >
                                            {name && (
                                                <span className={styles.treatmentPricesPriceLabel}>
                                                    {name}
                                                </span>
                                            )}
                                            {price ? (
                                                <span className={styles.treatmentPricesPriceValue}>
                                                    {`£${price}`}
                                                </span>
                                            ) : (
                                                <span
                                                    className={
                                                        styles.treatmentPricesPriceValueEmpty
                                                    }
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>

                {terms && (
                    <Row>
                        <Col xs>
                            <Divider />
                            <Card>
                                <Card.Content>
                                    <p>{terms}</p>
                                </Card.Content>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Grid>
        </Layout>
    );
};

export default Treatments;

export const query = () => graphql`
    query TreatmentsQuery($path: String!) {
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                path
                name: treatment_name
                seoTitle: treatment_seo_title
                seoDescription: treatment_seo_description
                buttonText: treatment_button_text
                buttonLink: treatment_button_link
                description: treatment_description
                terms: treatment_terms
                image: treatment_image {
                    childImageSharp {
                        fluid(maxHeight: 500) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                items: treatment_items {
                    name: treatment_items_name
                    description: treatment_items_description
                    prices: treatment_items_prices {
                        name: treatment_items_prices_name
                        price: treatment_items_prices_price
                    }
                    extras: treatment_items_extras {
                        extra: treatment_items_extras_extra
                    }
                }
            }
        }
    }
`;
