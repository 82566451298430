import React, { Fragment } from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '@components/Layout';
import Hero from '@components/Hero';
import CallToActions from '@components/CallToActions';
import { Grid, Row, Col, Divider } from '@components/Grid';
import Button from '@components/Button';

import styles from './styles.module.scss';
import SEO from '@components/SEO';
import Accordion from '@components/Accordion';
import { Card, CardActions, CardContent, CardHeader, Stack } from '@mui/material';
import { makePostRequest } from '@helpers/requests';
import { FORM } from '@helpers/api';
import * as Yup from 'yup';
import Input from '@components/Input';
import Select from '@components/Select';
import Alert from '@components/Alert';
import { Formik } from 'formik';
import { useSnackbar } from '@components/Snackbar';

const Spa = ({
    data: {
        form,
        treatments: { edges: treatments },
        page: {
            frontmatter: {
                heroTitle,
                heroDescription,
                heroImage,
                callToActions,
                faqs,
                bookPrimaryText,
                bookPrimaryLink,
                bookSecondaryLink,
                bookSecondaryText,
            },
        },
    },
}) => {
    const [openSnackbar] = useSnackbar();
    const handleSubmit = async (values, actions) => {
        try {
            const postData = {
                formId: form?._id,
                sections: Object.keys(values).map(sectionId => {
                    const formSection = form?.sections?.find(({ _id }) => _id === sectionId);

                    let sectionData = {
                        _id: sectionId,
                        fields: [],
                    };

                    for (let fieldId in values[sectionId]) {
                        if (values[sectionId].hasOwnProperty(fieldId)) {
                            const formSectionField = formSection?.fields?.find(
                                ({ _id }) => _id === fieldId
                            );

                            sectionData.fields.push({
                                _id: fieldId,
                                value:
                                    formSectionField?.type === 'dropdown' ||
                                    formSectionField?.type === 'radio'
                                        ? formSectionField?.options?.find(
                                              ({ _id }) => _id === values[sectionId][fieldId]
                                          )?.label
                                        : values[sectionId][fieldId],
                            });
                        }
                    }

                    return sectionData;
                }),
            };

            const { data: formData } = await makePostRequest(FORM, postData);
            openSnackbar('Your application form was successfully submitted.');
            actions?.resetForm();
        } catch (error) {
            error !== 'cancelled' &&
                openSnackbar(
                    error?.errorMessage ??
                        'An error occurred submitting this form, please try clearing your cache or try again later.'
                );
        }
    };

    const getFields = () => {
        const sections = {};

        for (let section of form?.sections) {
            sections[section._id] = {};

            for (let field of section?.fields) {
                sections[section._id][field?._id] = undefined;
            }
        }

        return sections;
    };

    const renderValidationField = ({ type, label, optional, validation }) => {
        let value;

        switch (type) {
            case 'string':
                value = Yup.string();
                if (validation?.type === 'text' && validation?.condition === 'email') {
                    value = value.email(validation?.error ?? 'Must be an email address.');
                }
                if (!optional) value = value.required(`${label} is required.`);
                return value;
            case 'paragraph':
            case 'dropdown':
                // case 'radio':
                value = Yup.string();
                if (!optional) value = value.required(`${label} is required.`);
                return value;
            // case 'checkbox':
            //     value = Yup.array().of(Yup.string());
            //     if (!optional) value = value.required(`${label} is required.`);
            //     return value;
        }

        return Yup.mixed().nullable();
    };

    const renderValidationFields = () => {
        const schema = {};

        Object.keys(form?.sections).forEach(sectionKey => {
            const section = form?.sections[sectionKey];
            const sectionId = section?._id;

            const fieldSchema = {};

            Object.keys(section?.fields).forEach(fieldKey => {
                const field = section?.fields[fieldKey];
                const fieldId = field?._id;

                fieldSchema[fieldId] = renderValidationField(field);
            });

            schema[sectionId] = Yup.object().shape(fieldSchema);
        });

        return Yup.object().shape(schema);
    };

    return (
        <Layout>
            <SEO
                title="Spa | Spa Break Suffolk | Retreat East"
                description="Visit our website to find out more about the spa breaks in suffolk we offer. From couples stays to a spa break with your best friends, we've got you covered."
            />
            <Hero image={heroImage} imageFullWidth>
                <h1>{heroTitle}</h1>
                <p>{heroDescription}</p>
                <Divider />

                <Stack
                    sx={{ my: 4 }}
                    spacing={2}
                    direction={{ md: 'row', xs: 'column' }}
                    justifyContent="center"
                >
                    {!!bookPrimaryLink && !!bookPrimaryText && (
                        <Button link={bookPrimaryLink} className={styles.spaBookButton}>
                            <span dangerouslySetInnerHTML={{ __html: bookPrimaryText }} />
                        </Button>
                    )}

                    {!!bookSecondaryLink && !!bookSecondaryText && (
                        <Button link={bookSecondaryLink} className={styles.spaBookButton}>
                            <span dangerouslySetInnerHTML={{ __html: bookSecondaryText }} />
                        </Button>
                    )}
                </Stack>

                <Button
                    secondary
                    link="https://retreat-east.vouchercart.com/app/category/135767/spa-135767"
                >
                    Buy Vouchers
                </Button>
            </Hero>

            <Grid>
                {treatments.map(
                    (
                        {
                            node: {
                                frontmatter: { path, name, overviewText, overviewImage },
                            },
                        },
                        index
                    ) => (
                        <Fragment key={path}>
                            <div className={styles.treatments}>
                                <Row middle="xs" reverse={index % 2 === 0}>
                                    <Col xs={12} md={6}>
                                        <div className={styles.treatmentsContent}>
                                            <h2>{name}</h2>
                                            <p>{overviewText}</p>
                                            <Button link={path}>View Treatments</Button>
                                        </div>
                                    </Col>
                                    <Col xs={12} md={6}>
                                        <Img
                                            className={styles.treatmentsImage}
                                            fluid={overviewImage.childImageSharp.fluid}
                                            alt={`Spa image ${index + 1}`}
                                        />
                                    </Col>
                                </Row>
                                <Divider margin={4} />
                            </div>
                        </Fragment>
                    )
                )}

                <Row middle="xs">
                    <Col xs={12}>
                        <Formik
                            initialValues={getFields()}
                            validationSchema={renderValidationFields()}
                            onSubmit={handleSubmit}
                        >
                            {props => (
                                <Fragment>
                                    <Row>
                                        <Col xs={12}>
                                            <SpaContactFormikForm {...props} form={form} />
                                        </Col>
                                    </Row>
                                </Fragment>
                            )}
                        </Formik>
                    </Col>
                </Row>
            </Grid>

            {!!faqs?.length &&
                faqs.map(({ question, answer }, index) => (
                    <Grid>
                        <Accordion key={`${question}_${index}`} title={question} text={answer} />
                    </Grid>
                ))}

            {callToActions && (
                <CallToActions>
                    {callToActions.map(callToAction => (
                        <CallToActions.CallToAction key={callToAction.title} {...callToAction} />
                    ))}
                </CallToActions>
            )}
        </Layout>
    );
};

const SpaContactFormikForm = ({
    values,
    handleChange,
    setFieldValue,
    isSubmitting,
    handleSubmit,
    touched,
    errors,
    form,
}) => {
    console.log({ form });
    return (
        <Card>
            <CardHeader title={form?.name} />
            <CardContent>
                {form?.sections?.map(section => (
                    <Fragment key={section?._id}>
                        {section?.fields?.map(field => {
                            const { _id, type, hint, label, optional } = field;
                            const name = `${section._id}.${_id}`;

                            const fieldProps = {
                                name,
                                optional: optional,
                                label: label,
                                hint: hint,
                                value: values?.[section?._id]?.[_id],
                                onChange: handleChange,
                                id: _id,
                            };

                            return (
                                <Fragment key={_id}>
                                    {type === 'string' && <Input {...fieldProps} />}
                                    {type === 'paragraph' && <Input {...fieldProps} textarea />}
                                    {/*{type === 'radio' && <Radios {...fieldProps} />}*/}
                                    {/*{type === 'checkbox' && <Checkboxes {...fieldProps} />}*/}
                                    {type === 'dropdown' && (
                                        <Select {...fieldProps}>
                                            {field?.options?.map(option => (
                                                <Select.Option
                                                    key={option?._id}
                                                    value={option?._id}
                                                    label={option?.label}
                                                />
                                            ))}
                                        </Select>
                                    )}

                                    {touched?.[section?._id]?.[_id] &&
                                        errors?.[section?._id]?.[_id] && (
                                            <Alert
                                                type="error"
                                                message={errors?.[section?._id]?.[_id]}
                                            />
                                        )}
                                </Fragment>
                            );
                        })}
                    </Fragment>
                ))}
            </CardContent>
            <CardActions sx={{ p: 2, pt: 0 }}>
                <Button disabled={isSubmitting} onClick={handleSubmit}>
                    Send
                </Button>
            </CardActions>
        </Card>
    );
};

export default Spa;

export const query = () => graphql`
    query SpaQuery($path: String!) {
        form: merlinForm(_id: { eq: "64baae9e0046e90014dd3d32" }) {
            _id
            name
            description
            meta {
                updatedTimestamp
            }
            sections {
                _id
                title
                description
                multiple
                min
                max
                fields {
                    label
                    hint
                    optional
                    options {
                        _id
                        label
                    }
                    validation {
                        type
                        condition
                        value
                        error
                    }
                    type
                    placeholder
                    id
                    _id
                }
            }
        }
        page: markdownRemark(frontmatter: { path: { eq: $path } }) {
            frontmatter {
                heroTitle: spa_hero_title
                heroDescription: spa_hero_description
                heroImage: spa_hero_image {
                    childImageSharp {
                        fluid(maxWidth: 1920) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
                bookPrimaryLink: spa_book_primary_link
                bookPrimaryText: spa_book_primary_text
                bookSecondaryLink: spa_book_secondary_link
                bookSecondaryText: spa_book_secondary_text

                faqs: spa_faqs_items {
                    question: spa_faqs_items_question
                    answer: spa_faqs_items_answer
                }
                callToActions: spa_call_to_actions {
                    title: spa_call_to_actions_title
                    description: spa_call_to_actions_description
                    image: spa_call_to_actions_image {
                        childImageSharp {
                            fluid(maxWidth: 1200) {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
        treatments: allMarkdownRemark(
            filter: { fileAbsolutePath: { regex: "/data/treatments/" } }
            sort: { fields: [frontmatter___treatment_priority], order: ASC }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        name: treatment_name
                        overviewText: treatment_overview_text
                        overviewImage: treatment_overview_image {
                            childImageSharp {
                                fluid(maxWidth: 500) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;
